export const environment = {
  production: false,
  apiUrl: "https://billing.cloudphysicianworld.com/api/",
  devResUrl: "https://billing.cloudphysicianworld.com/api/",
  vitalsUrl: "https://billing.cloudphysicianworld.com/api/",
  ptzApiUrl: "https://billing.cloudphysicianworld.com/api/",
  socketUrl: "https://billing.cloudphysicianworld.com/",
  aiSocketUrl: "https://billing.cloudphysicianworld.com/api_ai/",
  billingUrl: "https://billing.cloudphysicianworld.com/api_billing/",
  recaptchaKey: "6Le9MMAUAAAAAACoh3Dqm6siCb5ewnZ_svviFTbJ",
  passwordSecretKey: "CloudPhysician",
  wikiDomain: "api-public.cloudphysicianworld.org",
  s3region: "ap-south-1",
  socketPath: "/api/CP-EMR",
  s3bucket: "emr-dev-public",
};
