import { Observable, Subject, Subscription } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { select, Store } from "@ngrx/store";
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";

import * as fromOrder from "../../store/reducers/order";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import * as fromNotesReducers from "src/app/store/reducers/patient-chart/notes/index";
import * as fromChatIndex from "src/app/patient/chat/store/reducers";
import * as chatActions from "src/app/patient/chat/store/actions/chat.actions";
import {
  faComments,
  faFileMedicalAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
// import { Socket } from 'ngx-socket-io';
import { InputPopupService } from "../../shared/input-popup.service";
import { NgxPermissionsService } from "ngx-permissions";
import * as OrderActions from "../../store/actions/order/order-main.actions";
import * as fromUserReducer from "src/app/store/reducers/user";
import { socket } from "../../config/socket";
import { MatTabChangeEvent, MatTabNav } from "@angular/material/tabs";
import { Router } from "@angular/router";
import * as fromNoteStore from "../../note-module/store";
import { NotesService } from "src/app/note-module/services/notes.service";
import { NoteInputComponent } from "src/app/note-module/note-input/note-input.component";
import { UntypedFormArray, UntypedFormBuilder } from "@angular/forms";
import {
  makeNoteInputForms,
  updateNoteForms,
} from "src/app/note-module/note-input/note-templates/note.forms";
import { Cosign, NoteForm, Notes } from "src/app/note-module/model/notes.model";
import { SiblingService } from "src/app/services/sibling.service";

@Component({
  selector: "app-input-popup",
  templateUrl: "./input-popup.component.html",
  styleUrls: ["./input-popup.component.scss"],
})
export class InputPopupComponent implements OnInit, OnDestroy {
  @ViewChild("matTabNav") matTabNav: MatTabNav;
  @Input("isDischargeTimeElapsed") isDischargeTimeElapsed: boolean;
  @ViewChild("popupBoxCont") popupBoxCont: ElementRef;
  draftNotes$: Subscription;
  editNote: NoteForm;
  cosign: Cosign;

  @HostListener("keydown", ["$event"]) onKeyDown(e) {
    if (e.ctrlKey && e.shiftKey && e.keyCode == 191) {
      this.showPopup = !this.showPopup;
    }
  }

  /**
   * Declaration
   */
  private unsubscribe$: Subject<any> = new Subject<any>();
  public $orderInput: Observable<boolean>;
  public CPMRN;
  public encounters;
  public currentUser;
  public showPopup = false;
  public notesValue;
  public draftedNewNote;
  public currPatient;
  public selectedIndex: number = 0;
  public chatData = [];

  public patientHeader$ = this.store.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData),
    takeUntil(this.unsubscribe$)
  );

  public notes$ = this.store.pipe(
    select(fromNotesReducers.getNotesData), // sectiom will be removed since we only need drafted notes here
    takeUntil(this.unsubscribe$)
  );

  public chat$ = this.store.pipe(
    select(fromChatIndex.getChatMessages),
    takeUntil(this.unsubscribe$)
  );

  public draftIndicator$ = this.store.pipe(
    select(fromNoteStore.getAllDraftNotes),
    takeUntil(this.unsubscribe$)
  );

  public chatNoti$ = this.store.pipe(
    select(fromChatIndex.getNotificationStatus),
    takeUntil(this.unsubscribe$)
  );
  public users$ = this.store.pipe(
    select(fromUserReducer.getUser),
    takeUntil(this.unsubscribe$)
  );

  public showNotification: boolean = false;

  public allDraftNotes = [];

  faMedicalAlt = faFileMedicalAlt;
  faClipboard = faClipboard;
  faComments = faComments;

  popupTitle = "Orders, Notes, Chat & Roundaᴙ";
  tabIndexMap = {};

  @ViewChild(NoteInputComponent) noteForm: NoteInputComponent;

  constructor(
    private _siblingService: SiblingService,
    private _notesService: NotesService,
    public store: Store<{}>,
    private _inputPopupService: InputPopupService,
    private ngxPermissionsService: NgxPermissionsService,
    private router: Router,
    private _fb: UntypedFormBuilder
  ) {
    this.$orderInput = store.pipe(
      select(fromOrder.getInputTabActive),
      takeUntil(this.unsubscribe$)
    );
  }

  /**
   * Getting CPMRN
   * Subscribing for notifyNotesGetUpdate which will provide selected note object for updating
   */
  ngOnInit() {
    this.patientHeader$.subscribe((patient) => {
      if (patient && patient.CPMRN) {
        this.currPatient = patient;
        this.CPMRN = this.currPatient.CPMRN;
        this.encounters = this.currPatient.encounters;
      }
    });

    this.users$.subscribe((data) => (this.currentUser = data.currentUser));
    this.draftNotes$ = this.store
      .select(fromNoteStore.getAllDraftNotes)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data): void => {
        if (!this.editNote) {
          if (data?.length) {
            const sortData: Notes[] = data.sort(function (a, b) {
              return (
                new Date(b.timestamp).getDate() -
                new Date(a.timestamp).getDate()
              );
            });
            const draftPopData = sortData[sortData.length - 1];
            this.cosign = draftPopData.content?.cosign;
            this.displayNote = updateNoteForms(draftPopData, this.currentUser);
          } else this.displayNote = null;
        }
      });

    this._notesService.editNote$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((editNoteData) => {
        if (editNoteData) {
          this.showPopup = true;
          this.editNote = editNoteData;
          this.openPopup("notes");
        }
      });
    this._notesService.cosign$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((cosignData) => {
        if (cosignData) {
          this.cosign = cosignData;
        }
      });

    this.store
      .select(fromNoteStore.refreshPopUpStaus)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (value) {
          this.refreshNotes();
        }
      });

    this.$orderInput.subscribe((val) => {
      if (val) {
        this.showPopup = true;
        if (this.popupBoxCont)
          this.popupBoxCont.nativeElement.style.display = "block";

        setTimeout(() => {
          this.matTabNav.selectedIndex = 0;
        }, 10);
      } else {
        this.showPopup = false;
        if (this.popupBoxCont)
          this.popupBoxCont.nativeElement.style.display = "none";
      }
    });

    // subscribe to chat
    this.chat$.subscribe((data) => {
      if (data.length) {
        this.chatData = data;
      }
    });

    // listen to socket
    socket?.on("chat-hide-notification", (data) => {
      if (
        data?.readReciept?.email == this.currentUser.email &&
        data?.CPMRN == this.currPatient.CPMRN &&
        data?.encounters == this.currPatient.encounters
      ) {
        this.store.dispatch(chatActions.hideNotification());
      }
    });

    // open popup if something arrives in observable
    this._inputPopupService.openPopup$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((popupId) => {
        this.openPopup(popupId);
      });

    this.popupTitle = this.getPopupTitle();

    // listen to new change
    this.chatNoti$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      setTimeout(() => {
        this.showNotification = data;
      }, 0);
    });

    this.setPopupIndexMap();
  }

  refreshNotes() {
    this.showPopup = false;
    this.popupBoxCont.nativeElement.style.display = "none";
    this.noteForm.patientNotesForm.reset({
      noteTypeForm: { formNoteType: "Select Note Type" },
      noteReferenceId: "new",
    });
    (
      this.noteForm.patientNotesForm.controls[
        "formNoteAddendumArray"
      ] as UntypedFormArray
    ).clear();
    this.noteForm.newDraftData = null;
    this.noteForm.lastUpdatedAt = null;
    this.displayNote = null;
    this.editNote = null;
    this.cosign = null;
    this._notesService.showAddednumNote(false);
    this._notesService.popUpCloseTrigger(false);
    this.noteForm.templateData = null;
  }

  ngAfterViewInit() {
    this.popupBoxCont.nativeElement.style.display = "none";
  }
  /**
   * To control the tabs in popup window
   */
  // @ViewChild('tabs')

  /**
   * To unsubscribe all the Behaviour subjectes
   */
  ngOnDestroy() {
    this._notesService.showAddednumNote(false);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    socket?.removeAllListeners("chat-hide-notification");
  }

  /**
   * Storing indices to select a mat tab when their respective icon is clicked
   */
  setPopupIndexMap() {
    let index = 0;

    if (this.ngxPermissionsService.getPermission("place_order"))
      this.tabIndexMap["order"] = index++;

    if (this.ngxPermissionsService.getPermission("get_chat"))
      this.tabIndexMap["chat"] = index++;

    if (this.ngxPermissionsService.getPermission("create_note"))
      this.tabIndexMap["notes"] = index;
  }

  /**
   * This function is to load which tab should be loaded(orders/notes)
   * @param val will contains the id of the tab to be loaded while opening the popup
   */
  loadTab() {
    this.showPopup = true;
    // this.tabs.select(val);
  }
  public displayNote: NoteForm;
  openPopup(tabLabel) {
    if (tabLabel == "order") {
      this._siblingService.sendResetFlag("true");
    }
    this.selectedIndex = this.tabIndexMap[tabLabel];
    this.showPopup = true;
    this.popupBoxCont.nativeElement.style.display = "block";

    if (tabLabel === "chat" && this.selectedIndex === 0) {
      this.store.dispatch(chatActions.openChatTab());
    }

    setTimeout(() => {
      if (this.matTabNav) {
        if (tabLabel === "notes") {
          this.matTabNav.selectedIndex = this.selectedIndex;
          this.popupBoxCont.nativeElement.style.display = "block";
          if (this.displayNote || this.editNote) {
            const formData = this.editNote ? this.editNote : this.displayNote;
            makeNoteInputForms(this.noteForm, this.cosign, formData, this._fb);
            // cosign
            this._notesService.popUpCloseTrigger(true);
          }
        }
      }
    }, 100);
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (!this.showPopup) return;

    if (tabChangeEvent.index === this.tabIndexMap["chat"]) {
      this.store.dispatch(chatActions.openChatTab());
    } else this.store.dispatch(chatActions.closeChatTab());
  }

  listenToChange(event) {
    this.showPopup = event;
    this.popupBoxCont.nativeElement.style.display = event ? "block" : "none";
  }

  collapsePopup(origin = "btn") {
    this.store.dispatch(chatActions.closeChatTab());

    if (origin === "head") {
      this.showPopup = false;
      this.selectedIndex = null;
      this.popupBoxCont.nativeElement.style.display = "none";
      this.store.dispatch(new OrderActions.CloseInputTab());
      this.displayNote = null;
      this.editNote = null;
      this._notesService.showAddednumNote(false);
      this._notesService.popUpCloseTrigger(true);
    }
  }

  getPopupTitle(): string {
    if (!this.ngxPermissionsService.getPermission("read_note")) {
      return "Chat";
    }

    if (
      this.ngxPermissionsService.getPermission("read_note") &&
      !this.ngxPermissionsService.getPermission("place_order")
    ) {
      return "Notes & Chat";
    }

    return "Orders, Notes, Chat & Roundaᴙ";
  }

  /** To add new patient*/
  addNewPatient() {
    this.router.navigate(["admitPatient"]);
  }

  getPatientAge() {
    const ageObj = this.currPatient?.age;

    if (!ageObj) return "";

    return ageObj.year
      ? `${ageObj.year} yr`
      : ageObj.month
      ? `${ageObj.month} mos`
      : ageObj.day
      ? `${ageObj.day} days`
      : ageObj.hour
      ? `${ageObj.hour} hrs`
      : "";
  }
}
