import { UserRoles } from "src/app/shared/accessControl/roleInterface";

export enum ImpactNotes {
  Event = "Event",
  Progress = "Progress",
  Nursing = "Nursing",
  Consult = "Consult note",
}

const cmnSignPermissions = [
  "Admission",
  "Event",
  "Nursing",
  "Progress",
  "Procedure",
  "ICU to ward transfer",
  "Consult note",
  "Discharge",
  "Death summary",
  "Shift handover note",
  "Tele critical care review",
  "Intensivist review",
];

const cmnAttestPermissions = [
  "Admission",
  "Event",
  "Progress",
  "Procedure",
  "Consult note",
];

export const signPermission = new Map([
  [UserRoles.PHYSICIAN, cmnSignPermissions],
  [UserRoles.SPECIALIST, cmnSignPermissions],
  [UserRoles.CCAPHYSICIAN, cmnSignPermissions],
  [UserRoles.NURSE, ["Event", "Nursing"]],
  [UserRoles.CCN, ["Event", "Nursing"]],
  [UserRoles.CCA, ["Event", "Nursing", "Shift handover note"]],
  [UserRoles.DIETITIAN, ["Event", "Nutrition"]],
  [UserRoles.PHARMACOLOGIST, ["Event", "Pharmacology"]],
  [UserRoles.DS, ["Event", "Nursing"]],
  [UserRoles.PT, ["Event", "Physiotherapy note"]],
  [UserRoles.OT, ["Event", "Nursing"]],
  [UserRoles.RT, ["Event", "Respiratory therapy note"]],
  [UserRoles.PA, ["Event", "Shift handover note"]],
]);

export const attestRequired = new Map([
  [UserRoles.SPECIALIST, cmnAttestPermissions],
  [UserRoles.CCAPHYSICIAN, cmnAttestPermissions],
  [UserRoles.PA, cmnAttestPermissions],
]);

export const toAttestRequired = new Map([
  [UserRoles.SPECIALIST, [UserRoles.PHYSICIAN, UserRoles.SPECIALIST]],
  [
    UserRoles.CCAPHYSICIAN,
    [UserRoles.PHYSICIAN, UserRoles.SPECIALIST, UserRoles.CCAPHYSICIAN],
  ],
  [
    UserRoles.PA,
    [
      UserRoles.PHYSICIAN,
      UserRoles.SPECIALIST,
      UserRoles.CCAPHYSICIAN,
      UserRoles.PA,
    ],
  ],
]);

export const header =
  (noteType: string) =>
  (noteSubType: string): string[] => {
    switch (noteType) {
      case "Admission":
      case "Progress":
      case "Procedure":
      case "Consult note":
      case "Intensivist review":
      case "Tele critical care review":
      case "ICU to ward transfer": {
        switch (noteSubType) {
          case "NICU Template":
          case "NICU progress note L1":
          case "NICU progress note L3":
          case "NICU progress note L2": {
            if (noteType == "Admission") {
              return [
                "Summary",
                "History",
                "Admission details",
                "Systemic examination",
                "Diagnosis",
                "Plan of treatment",
              ];
            } else {
              return [
                "Summary",
                "Assessment",
                "Diagnosis",
                "Active medications",
                "Labs and investigations",
                "Plan of treatment",
              ];
            }
          }
          default: {
            return [
              "Summary",
              "Diagnosis",
              "Assessment",
              "Medication",
              "Reports",
              "To-do's",
            ];
          }
        }
      }
      case "Death summary": {
        return [
          "Date and time of death",
          "Admission Diagnosis",
          "Procedures/Surgery",
          "Course of hospital stay",
          "Medications",
          "Cause of death",
        ];
      }
      case "Nursing": {
        switch (noteSubType) {
          case "NICU Template": {
            return [
              "Basic information",
              "Patient Specific Monitoring",
              "Systemic Assessment",
              "Medication",
              "Investigation",
              "To-do's",
            ];
          }
          case "MICU Template": {
            return ["", "Diagnosis", "Assessment", "", "", "To-do's"];
          }
          case "Nursing Short Note": {
            return ["", "Diagnosis", "Assessment", "", "", "To-do's"];
          }
          default: {
            return ["Summary"];
          }
        }
      }
      case "Discharge": {
        switch (noteSubType) {
          case "NICU Template": {
            return [
              "Patient details",
              "Diagnosis",
              "Course of hospital stay",
              "",
              "Discharge Exam",
              "Discharge advice",
            ];
          }

          default: {
            return ["Summary"];
          }
        }
      }
      default: {
        return ["Summary"];
      }
    }
  };
