<app-loader *ngIf="showLoader"></app-loader>

<header class="site-header">
  <nav class="navbar navbar-expand-md navbar-dark w-100">
    <a (click)="onMainLogoClick()">
      <img
        class="cp-logo-main"
        src="assets/images/radar_logo.svg"
        alt="Cloudphycian Logo"
      />
    </a>
    <!-- <img class="cp-logo-main" src="../../assets/icons/radar_logo_black.png" alt="Cloudphycian Logo"> -->

    <!-- Login Page Options -->
    <!-- <div class="header-btn" *ngIf="!user">
      <div class="cp-btn cp-hvr-brand-p" routerLink="/login">Login</div>
      <div class="cp-btn cp-hvr-brand-p" routerLink="/register">Register</div>
    </div> -->

    <!-- When R-alert user log's in -->
    <ng-container *ngxPermissionsOnly="['view_header_unit']">
      <div class="cp-nav-center" *ngIf="tvUnitName$ | async">
        <div class="cp-nav-center__tv-unit-change">
          <span class="cp-color-dark cp-mr-2">{{ tvUnitName$ | async }}</span>
          <span
            class="cp-color-primary cp-cursor-pointer"
            (click)="resetTvUnit()"
            >Change unit</span
          >
        </div>
      </div>
    </ng-container>
    <div class="cp-nav-center ralert-text" *ngIf="user?.role === 'R-Alert'">
      R-Aleᴙt
    </div>
    <div class="navbar-right ralert-counter" *ngIf="user?.role === 'R-Alert'">
      <span>{{ ralertMessagesCount$ | async }}</span>
    </div>

    <!-- User info and action -->
    <div class="header-btn" style="display: flex">
      <!-- eCall and CodeBlue button -->
      <div class="button-section" *ngxPermissionsOnly="['create_ralert']">
        <div
          class="ecall-button"
          (click)="openEcallPopup(ECallPopupContext.ECall)"
        >
          eCall
        </div>
        <div
          class="code-blue-button"
          (click)="openEcallPopup(ECallPopupContext.CodeBlue)"
        >
          CodeBlue
        </div>
        <div class="vertical-line"></div>
      </div>

      <div class="global-search">
        <app-global-search-patient
          *ngxPermissionsOnly="['search_patient']"
        ></app-global-search-patient>
      </div>

      <div
        class="notification-center"
        *ngIf="
          ['Nurse', 'Critical Care Nurse'].includes(user?.role) &&
          user?.isCommandCenterUser
        "
      >
        <app-notifications></app-notifications>
      </div>

      <ng-container *ngxPermissionsOnly="['update_on_call']">
        <div
          *ngIf="!user?.client"
          class="availability-status d-inline"
          display="dynamic"
          [matMenuTriggerFor]="updateOnCall"
        >
          <div class="user-status">
            <div
              [class]="[
                user?.onCallStatus?.onCall
                  ? 'red-dot'
                  : user?.onCallStatus?.onCall === false
                  ? 'green-dot'
                  : 'black-dot'
              ]"
            ></div>
            <div
              [class]="[
                user?.onCallStatus?.onCall
                  ? 'red'
                  : user?.onCallStatus?.onCall === false
                  ? 'green'
                  : 'black'
              ]"
            >
              {{
                user?.onCallStatus?.onCall
                  ? "Busy"
                  : user?.onCallStatus?.onCall === false
                  ? "Available"
                  : "Offline"
              }}
            </div>
          </div>

          <mat-menu #updateOnCall="matMenu" xPosition="after">
            <div
              mat-menu-item
              class="user-status-item"
              (click)="onStatusChange(false)"
            >
              <div class="green-dot"></div>
              <div class="green">Available</div>
            </div>
            <div
              mat-menu-item
              class="user-status-item"
              (click)="onStatusChange(true)"
            >
              <div class="red-dot"></div>
              <div class="red">Busy</div>
            </div>
            <div
              mat-menu-item
              class="user-status-item"
              (click)="onStatusChange(null)"
            >
              <div class="black-dot"></div>
              <div class="black">Offline</div>
            </div>
          </mat-menu>
        </div>
      </ng-container>

      <div
        class="user-options-menu"
        *ngIf="currUserStatus$ | async"
        [matMenuTriggerFor]="userOptions"
      >
        <div class="user-options">
          <span class="user-name padding">
            {{ user?.name }}
            <span *ngIf="user?.role === NURSE">(RN)</span>
            <span *ngIf="user?.role === CCN">(CCRN)</span>
            <span *ngIf="user?.role === CCA">(CCA)</span>
          </span>

          <span class="avatar-icon padding">
            <app-avatar [avatarParams]="avatarParams"></app-avatar>
          </span>
        </div>

        <mat-menu #userOptions="matMenu" xPosition="before">
          <div
            *ngIf="!user?.isFamilyUser"
            mat-menu-item
            class="dropdown-item"
            (click)="openChangePwModal()"
          >
            Change password
          </div>
          <div
            mat-menu-item
            *ngIf="user?.allowedRoles?.length > 1"
            class="dropdown-item"
            (click)="openChangeRoleModal()"
          >
            Change role
          </div>
          <div mat-menu-item class="dropdown-item" (click)="openRadarHelp()">
            RADAR help
          </div>
          <div mat-menu-item class="dropdown-item" (click)="logoutUser()">
            Logout
          </div>
        </mat-menu>
      </div>
    </div>
  </nav>
</header>
<div class="header-divider"></div>
